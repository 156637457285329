import React, { useEffect } from 'react'
import { ConfirmationPage } from '@wallyhealth/web-core'
import intelService from '../../services/intelService'

const WelcomePage = () => {
  const recordView = () => {
    intelService.recordView({ viewName: 'WelcomePage' })
  }

  const onMount = () => {
    recordView()
  }

  useEffect(() => {
    onMount()
  }, [])

  return (
    <ConfirmationPage
      title="Welcome to Wally!"
      subtitle="Your care team will text you soon and help you prepare for your first visit. We look forward to seeing you!"
    />
  )
}

export default WelcomePage
